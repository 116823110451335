import { createStore } from "vuex";

import {
  store as geral,
  GeralStore,
  State as GeralState,
} from "./modules/geral";
import {
  store as dadosPessoais,
  DadosPessoaisStore,
  State as DadosPessoaisState,
} from "./modules/DadosPessoais";

export type RootState = {
  geral: GeralState;
  dadosPessoais: DadosPessoaisState;
};

export type Store = GeralStore<Pick<RootState, "geral">> &
  DadosPessoaisStore<Pick<RootState, "dadosPessoais">>;

// const debug = process.env.NODE_ENV !== 'production';
// const plugins = debug ? [createLogger({})] : [];

export const store = createStore({
  plugins: [],

  modules: {
    geral,
    dadosPessoais,
  },
});

export function useStore(): Store {
  return store as Store;
}
