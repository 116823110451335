import reusableFunctions from "@/utils/functions";
import { AxiosError, AxiosInstance } from "axios";

export async function post(
  httpClient: AxiosInstance,
  ordemCompra: OrdemCompra
): Promise<{
  data: Proposta | null;
  errors: AxiosError<DataError> | null;
}> {
  let errors = null;
  let data = null;
  await httpClient
    .post("api/v1/publicdata/application", ordemCompra)
    .then((response) => {
      data = response.data;
    })
    .catch((e) => {
      errors = e;
    });
  return { data, errors };
}
export async function login(
  httpClient: AxiosInstance,
  login: Login
): Promise<{
  data: Autenticacao | null;
  errors: AxiosError | null;
}> {
  let errors = null;
  let data = null;
  const qs = reusableFunctions().queryString(login);
  await httpClient
    .post("api/v1/oauth/token", qs)
    .then((response) => {
      data = response.data;
    })
    .catch((e) => {
      errors = e;
    });
  return { data, errors };
}

export default (httpClient: AxiosInstance) => ({
  enviarOrdemCompra: async (ordemCompra: OrdemCompra) => {
    const { data, errors } = await post(httpClient, ordemCompra);
    return { data, errors };
  },
  login: async (loginForm: Login) => {
    const { data, errors } = await login(httpClient, loginForm);
    return { data, errors };
  },
});
