<template>
  <teleport to="body">
    <div
      class="modal fade"
      id="myModal"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="myModal"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable"
      >
        <component
          v-if="valueComputed"
          :is="state.component"
          v-bind="state.props"
        />
      </div>
    </div>
  </teleport>
</template>
<script lang="ts">
import {
  defineAsyncComponent,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  computed,
} from "vue";
import { Modal } from "bootstrap";
const ModalInfoGenerico = defineAsyncComponent(
  () => import("./ModalInfoGenerico.vue")
);
const ModalTabelaAmortizacao = defineAsyncComponent(
  () => import("./ModalTabelaAmortizacao.vue")
);
const ModalCamera = defineAsyncComponent(() => import("./ModalCamera.vue"));

const ModalProvaVida = defineAsyncComponent(
  () => import("./ModalProvaVida.vue")
);

const ModalSair = defineAsyncComponent(() => import("./ModalSair.vue"));

const ModalAvisoPrivacidade = defineAsyncComponent(
  () => import("./ModalAvisoPrivacidade.vue")
);

const ModalReprovado = defineAsyncComponent(
  () => import("./ModalReprovado.vue")
);

const ModalDadosIncorretos = defineAsyncComponent(
  () => import("./ModalDadosIncorretos.vue")
);

const ModalConfirmaDadosPessoais = defineAsyncComponent(
  () => import("./ModalConfirmaDadosPessoais.vue")
);

const ModalRejection = defineAsyncComponent(
  () => import("./ModalRejection.vue")
);

import useModal from "../../hooks/useModal";

export default defineComponent({
  components: {
    ModalInfoGenerico,
    ModalTabelaAmortizacao,
    ModalCamera,
    ModalSair,
    ModalProvaVida,
    ModalAvisoPrivacidade,
    ModalReprovado,
    ModalDadosIncorretos,
    ModalConfirmaDadosPessoais,
    ModalRejection,
  },
  setup() {
    let myModal: Modal;
    const modal = useModal();
    const state = reactive({
      isActive: false,
      component: {},
      props: {},
    });

    onMounted(() => {
      modal.listen(handleModalToogle);
    });
    onBeforeUnmount(() => {
      modal.off(handleModalToogle);
    });
    function handleModalToogle(payload: any) {
      state.isActive = payload.status;
      if (payload.status) {
        state.component = payload.component;
        state.props = payload.props;
        myModal = new Modal(document.getElementById("myModal") as HTMLElement);
        myModal.show();
      } else {
        state.component = {};
        state.props = {};
        myModal.hide();
      }
    }
    const valueComputed = computed(() => {
      return state.isActive;
    });
    return { state, handleModalToogle, valueComputed };
  },
});
</script>

<style></style>
