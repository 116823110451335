import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { store } from "@/store";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "ServicoSupenso",
    component: () =>
      import(
        /* webpackChunkName: "ServicoSuspensoView" */ "../views/ServicoSuspensoView.vue"
      ),
  },
  /*
  {
    path: "/",
    name: "Home",
    component: () =>
      import(
        /* webpackChunkName: "HomeView" "../views/HomeView.vue"
        ),
      },
  {
    path: "/buyOrder",
    name: "OrdemCompra",
    component: () =>
      import(
         webpackChunkName: "OrdemCompraView" "../views/OrdemCompraView.vue"
      ),
  },
  {
    path: "/onboard",
    name: "Onboard",
    component: () =>
      import(/* webpackChunkName: "OnboardView" "../views/OnboardView.vue"),
  },
  {
    path: "/error",
    name: "Error",
    component: () =>
      import(/* webpackChunkName: "OnboardView"  "../views/ErrorView.vue"),
  },
  {
    path: "/offer",
    name: "Oferta",
    component: () =>
      import(/* webpackChunkName: "OfertaView" "../views/OfertaView.vue"),
    meta: { hasAuth: true },
  },
  {
    path: "/personalData",
    name: "DadosPessoais",
    component: () =>
      import(
        /* webpackChunkName: "DadosPessoaisView" "../views/DadosPessoaisView.vue"
      ),
    meta: { hasAuth: true },
  },
  {
    path: "/email",
    name: "Email",
    component: () =>
      import(/* webpackChunkName: "EmailView" "../views/EmailView.vue"),
    meta: { hasAuth: true },
  },
  {
    path: "/approved",
    name: "Aprovado",
    component: () =>
      import(
        /* webpackChunkName: "AprovadoView"  "../views/AprovadoView.vue"
      ),
    meta: { hasAuth: true },
  },
  {
    path: "/identity",
    name: "Identidade",
    component: () =>
      import(
        /* webpackChunkName: "IdentidadeView" "../views/IdentidadeView.vue"
      ),
    meta: { hasAuth: true },
  },
  {
    path: "/summaryLoan",
    name: "Resumo",
    component: () =>
      import(/* webpackChunkName: "ResumoView" "../views/ResumoView.vue"),
    meta: { hasAuth: true },
  },
  {
    path: "/verification",
    name: "Verificacao",
    component: () =>
      import(
        /* webpackChunkName: "VerificacaoView"  "../views/VerificacaoView.vue"
      ),
    meta: { hasAuth: true },
  },
  {
    path: "/confirmationCode",
    name: "ConfirmacaoCodigo",
    component: () =>
      import(
        /* webpackChunkName: "VerificacaoView" "../views/ConfirmacaoCodigoView.vue"
      ),
    meta: { hasAuth: true },
  },
  {
    path: "/fullSummaryLoan",
    name: "ResumoFinal",
    component: () =>
      import(
        /* webpackChunkName: "VerificacaoView" "../views/ResumoFinalView.vue"
      ),
    meta: { hasAuth: true },
  },
  {
    path: "/cartRecovery",
    name: "RecuperacaoDeCarrinho",
    component: () =>
      import(
        /* webpackChunkName: "RecuperacaoDeCarrinhoView" "../views/RecuperacaoCarrinhoView.vue"
      ),
    meta: { hasAuth: true },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "Home" },
  },*/
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// -- Guarda de rotas da aplicação
router.beforeEach(async (to) => {
  const token = store.getters.autenticacao.access_token;
  if (to.meta.hasAuth) {
    if (!token) {
      return { name: "OrdemCompra" };
    } else if (token) {
      return;
    }
  } else {
    return;
  }
});

export default router;
