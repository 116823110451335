<template>
  <div class="idle-bottom">
    <div v-if="state.exibir" class="idle">
      <img class="idle-img" src="~@/assets/icons/movistar.svg" alt="" />
      <div>
        <div class="idle-title">Cierre de sesión</div>
        <div class="idle-text">
          Se cerrará tu sesión por inactividad. Puedes iniciar de nuevo y
          continuar con tu solicitud en donde te quedaste
        </div>
        <div class="w-100 d-inline-flex my-3">
          <button class="btn btn-verde btn-idle" @click="continuar()">
            Quiero continuar
          </button>
          <button class="btn btn-link btn-idle ms-3" @click="sair()">
            Entendido
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, watch } from "vue";
import { useRoute } from "vue-router";
import useModal from "@/hooks/useModal";
import reusableFunctions from "@/utils/functions";

export default defineComponent({
  setup() {
    const modal = useModal();
    const route = useRoute();
    const state = reactive({
      nomeRota: "",
      IDLE_TIMEOUT: 180,
      idleSecondsTimer: 0,
      idleSecondsCounter: 0,
      exibir: false,
      RESPOSTA_TIMEOUT: 60,
      respostaSecondsTimer: 0,
      respostaSecondsCounter: 0,
    });

    function startIdle() {
      window.clearInterval(state.respostaSecondsTimer);
      window.clearInterval(state.idleSecondsTimer);
      state.respostaSecondsCounter = 0;
      state.idleSecondsCounter = 0;
      document.onclick = () => {
        state.idleSecondsCounter = 0;
      };
      document.onmousemove = () => {
        state.idleSecondsCounter = 0;
      };
      document.onkeypress = () => {
        state.idleSecondsCounter = 0;
      };
      state.idleSecondsTimer = window.setInterval(checkIdleTime, 1000);
    }
    function checkIdleTime() {
      state.idleSecondsCounter++;
      if (state.idleSecondsCounter >= state.IDLE_TIMEOUT) {
        state.exibir = true;
        window.clearInterval(state.idleSecondsTimer);
        state.respostaSecondsTimer = window.setInterval(respostaTime, 1000);
      }
    }
    async function respostaTime() {
      state.respostaSecondsCounter++;
      if (state.respostaSecondsCounter >= state.RESPOSTA_TIMEOUT) {
        reseteResposta();
        //chamar açao de deslogar
        await deslogar();
      }
    }
    async function deslogar() {
      console.log("Efetuando Logout por tempo ocioso ");
      state.exibir = false;
      try {
        modal.close();
      } catch (error) {
        console.log(error);
      }
      await reusableFunctions().logOut();
    }
    function continuar() {
      state.exibir = false;
      reseteResposta();
      startIdle();
    }
    async function sair() {
      await deslogar();
    }
    function reseteResposta() {
      window.clearInterval(state.respostaSecondsTimer);
      state.respostaSecondsCounter = 0;
      state.idleSecondsCounter = 0;
    }

    watch(
      () => route.name as string,
      (rotaName: string) => {
        if (!["Home", "OrdemCompra", "Onboard"].includes(rotaName)) {
          startIdle();
        }
      }
    );
    return { state, continuar, sair };
  },
});
</script>

<style lang="scss">
.idle-bottom {
  position: fixed;
  bottom: 1em;
  flex-direction: column-reverse;
}
@media only screen and (max-width: 480px) {
  .idle {
    margin: 0 1rem;
  }
}
.idle {
  max-width: 30rem;
  display: inline-flex;
  padding: 1rem;
  background: #f6f6f6;
  height: 168px;
}
.idle-img {
  margin-right: 1rem;
  width: 42px;
  height: 42px;
}
.idle-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #313235;
}
.idle-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #86888c;
}
.btn-idle {
  height: 35px !important;
  padding: 0 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}
</style>
