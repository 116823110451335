import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import Maska from "maska";

import "./assets/styles/app.scss";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import Popper from "vue3-popper";
import VueformSlider from "@vueform/slider";

import Toast, { PluginOptions, POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

const options: PluginOptions = {
  timeout: 5000,
  position: POSITION.BOTTOM_CENTER,
  // You can set your default options here
};

const app = createApp(App);
app.use(Toast, options);
app.use(Maska);
app.use(store);
app.use(router);
// eslint-disable-next-line
app.component("Popper", Popper);
// eslint-disable-next-line
app.component("Slider", VueformSlider);
app.mount("#app");
//createApp(App).use(store).use(router).mount('#app')
