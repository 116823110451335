import { ActionContext, ActionTree } from "vuex";
import { Mutations } from "./mutations";
import { State } from "./state";
import { GeralActionTypes } from "./action-types";
import { GeralMutationTypes } from "./mutation-types";
import { RootState } from "@/store";
import services from "../../../services";
import { AxiosError } from "axios";
import DeviceDetector from "device-detector-js";

//actions
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

// actions interface
export interface Actions {
  [GeralActionTypes.ENVIAR_ORDEM_COMPRA](
    { commit }: AugmentedActionContext,
    payload: OrdemCompra
  ): Promise<{
    data: Proposta | null;
    errors: AxiosError<DataError, any> | null;
  }>;
  [GeralActionTypes.BUSCAR_PROPOSTA]({
    commit,
  }: AugmentedActionContext): Promise<{
    data: Proposta | null;
    errors: AxiosError<DataError, any> | null;
  }>;
  [GeralActionTypes.EFETUAR_LOGIN](
    { commit }: AugmentedActionContext,
    payload: Login
  ): Promise<{
    data: Autenticacao | null;
    errors: AxiosError<unknown, any> | null;
  }>;
  [GeralActionTypes.ENVIAR_OFERTA_SELECIONADA](
    { commit }: AugmentedActionContext,
    payload: OfertaSelecionada
  ): Promise<{
    data: Proposta | null;
    errors: AxiosError<unknown, any> | null;
  }>;
  [GeralActionTypes.ENVIAR_AUTORIZACOES](
    { commit }: AugmentedActionContext,
    payload: OrdemCompra
  ): Promise<{
    data: Proposta | null;
    errors: AxiosError<unknown, any> | null;
  }>;
  [GeralActionTypes.GET_INFO_DEVICE]({ commit }: AugmentedActionContext): void;
  [GeralActionTypes.ENVIAR_TOKEN](
    { commit }: AugmentedActionContext,
    payload: Token
  ): Promise<{
    data: Proposta | null;
    errors: AxiosError<DataError, any> | null;
  }>;
  [GeralActionTypes.LIBERAR_PROPOSTA]({
    commit,
  }: AugmentedActionContext): Promise<{
    data: Proposta | null;
    errors: AxiosError<unknown, any> | null;
  }>;
  [GeralActionTypes.VALIDAR_LIGACAO]({
    commit,
  }: AugmentedActionContext): Promise<{
    data: Proposta | null;
    errors: AxiosError<unknown, any> | null;
  }>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [GeralActionTypes.ENVIAR_ORDEM_COMPRA](
    { commit },
    payload: OrdemCompra
  ) {
    const { data, errors } = await services.ordemCompra.enviarOrdemCompra(
      payload
    );
    if (!errors && data) {
      commit(GeralMutationTypes.INSERIR_ORDEM_COMPRA, payload);
      commit(GeralMutationTypes.INSERIR_PROPOSTA, data);
    }
    return { data, errors };
  },

  async [GeralActionTypes.BUSCAR_PROPOSTA]({ commit }) {
    const { data, errors } = await services.bbva.buscarProposta();
    if (!errors && data) {
      commit(GeralMutationTypes.INSERIR_PROPOSTA, data);
    }
    return { data, errors };
  },

  async [GeralActionTypes.ENVIAR_OFERTA_SELECIONADA](
    { commit },
    payload: OfertaSelecionada
  ) {
    const { data, errors } = await services.oferta.enviar(payload);
    if (!errors && data) {
      commit(GeralMutationTypes.INSERIR_PROPOSTA, data);
    }
    return { data, errors };
  },

  async [GeralActionTypes.ENVIAR_AUTORIZACOES](
    { commit },
    payload: OrdemCompra
  ) {
    const { data, errors } = await services.bbva.enviarAutorizacoes(payload);
    if (!errors && data) {
      commit(GeralMutationTypes.INSERIR_PROPOSTA, data);
    }
    return { data, errors };
  },

  async [GeralActionTypes.EFETUAR_LOGIN]({ commit }, payload: Login) {
    const { data, errors } = await services.ordemCompra.login(payload);
    if (!errors && data) {
      commit(GeralMutationTypes.SET_AUTENTICACAO, data);
    }
    return { data, errors };
  },
  async [GeralActionTypes.GET_INFO_DEVICE]({ commit }) {
    const deviceDetector = new DeviceDetector();
    const abc = deviceDetector.parse(window.navigator.userAgent);
    commit(GeralMutationTypes.SET_DEVICE, abc);
  },

  async [GeralActionTypes.ENVIAR_TOKEN]({ commit }, payload: Token) {
    const { data, errors } = await services.bbva.enviarToken(payload);
    if (!errors && data) {
      commit(GeralMutationTypes.INSERIR_PROPOSTA, data);
    }
    return { data, errors };
  },

  async [GeralActionTypes.LIBERAR_PROPOSTA]({ commit }) {
    const { data, errors } = await services.bbva.liberarProposta();
    if (!errors && data) {
      commit(GeralMutationTypes.INSERIR_PROPOSTA, data);
    }
    return { data, errors };
  },
  async [GeralActionTypes.VALIDAR_LIGACAO]({ commit }) {
    const { data, errors } = await services.bbva.validarLigacao();
    if (!errors && data) {
      commit(GeralMutationTypes.INSERIR_PROPOSTA, data);
    }
    return { data, errors };
  },
};
