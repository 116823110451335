import { AxiosError, AxiosInstance } from "axios";

async function postToken(
  httpClient: AxiosInstance,
  codigo: Token
): Promise<{
  data: Proposta | null;
  errors: AxiosError<DataError> | null;
}> {
  let errors = null;
  let data = null;
  await httpClient
    .post("api/v1/application/validate-token", codigo)
    .then((response) => {
      data = response.data;
    })
    .catch((e) => {
      errors = e;
    });
  return { data, errors };
}
async function postLiberarProposta(httpClient: AxiosInstance): Promise<{
  data: Proposta | null;
  errors: AxiosError | null;
}> {
  let errors = null;
  let data = null;
  await httpClient
    .post("api/v1/application/release-proposal")
    .then((response) => {
      data = response.data;
    })
    .catch((e) => {
      errors = e;
    });
  return { data, errors };
}
async function postChamarLigacao(httpClient: AxiosInstance): Promise<{
  data: any;
  errors: AxiosError | null;
}> {
  let errors = null;
  let data = null;
  await httpClient
    .post("api/v1/application/make-phonecall")
    .then((response) => {
      data = response.data;
    })
    .catch((e) => {
      errors = e;
    });
  return { data, errors };
}
async function postConfirmarLigacao(httpClient: AxiosInstance): Promise<{
  data: any;
  errors: AxiosError | null;
}> {
  let errors = null;
  let data = null;
  await httpClient
    .post("api/v1/application/verify-phonecall")
    .then((response) => {
      data = response.data;
    })
    .catch((e) => {
      errors = e;
    });
  return { data, errors };
}
async function getBuscarProposta(httpClient: AxiosInstance): Promise<{
  data: Proposta | null;
  errors: AxiosError<DataError> | null;
}> {
  let errors = null;
  let data = null;
  await httpClient
    .get("api/v1/application")
    .then((response) => {
      data = response.data;
    })
    .catch((e) => {
      errors = e;
    });
  return { data, errors };
}

export async function postAutorizacoes(
  httpClient: AxiosInstance,
  ordemCompra: OrdemCompra
): Promise<{
  data: Proposta | null;
  errors: AxiosError | null;
}> {
  let errors = null;
  let data = null;
  await httpClient
    .post("api/v1/application/save-authorizations", ordemCompra)
    .then((response) => {
      data = response.data;
    })
    .catch((e) => {
      errors = e;
    });
  return { data, errors };
}

export default (httpClient: AxiosInstance) => ({
  enviarToken: async (codigo: Token) => {
    const { data, errors } = await postToken(httpClient, codigo);

    return { data, errors };
  },
  chamarLigacao: async () => {
    const { data, errors } = await postChamarLigacao(httpClient);
    return { data, errors };
  },
  validarLigacao: async () => {
    const { data, errors } = await postConfirmarLigacao(httpClient);
    return { data, errors };
  },
  liberarProposta: async () => {
    const { data, errors } = await postLiberarProposta(httpClient);
    return { data, errors };
  },
  buscarProposta: async () => {
    const { data, errors } = await getBuscarProposta(httpClient);
    return { data, errors };
  },
  enviarAutorizacoes: async (ordemCompra: OrdemCompra) => {
    const { data, errors } = await postAutorizacoes(httpClient, ordemCompra);
    return { data, errors };
  },
});
