import { DeviceDetectorResult } from "device-detector-js";

const ORDEM_COMPRA = "ordemCompra";
const DADOS_PESSOAIS = "dadosPessoais";
const PROPOSTA = "proposta";
const AUTENTICACAO = "autenticacao";
const TOKENRECAPTCHA = "TokenRecaptcha";
const IP = "ip";
const DEVICE = "device";
const CARRINHO = "carrinho";
const CALL = "call";

function saveLocalStorage(
  nome: string,
  valor: string | number | boolean | object
) {
  if (valor) {
    window.localStorage.setItem(nome, JSON.stringify(valor));
  }
}

export const Persist = {
  saveOrdemCompra: function (value: OrdemCompra) {
    saveLocalStorage(ORDEM_COMPRA, value);
  },
  getOrdemCompra: function () {
    let dados = {} as OrdemCompra;
    if (window.localStorage.getItem(ORDEM_COMPRA)) {
      const stringDados = window.localStorage.getItem(ORDEM_COMPRA);
      if (stringDados) {
        dados = JSON.parse(stringDados);
      }
    }
    return dados;
  },
  clearOrdemCompra: function () {
    window.localStorage.removeItem(ORDEM_COMPRA);
  },

  saveDadosdPessoais: function (value: DadosPessoais) {
    saveLocalStorage(DADOS_PESSOAIS, value);
  },
  getDadosdPessoais: function () {
    let dados = {} as DadosPessoais;
    if (window.localStorage.getItem(DADOS_PESSOAIS)) {
      const stringDados = window.localStorage.getItem(DADOS_PESSOAIS);
      if (stringDados) {
        dados = JSON.parse(stringDados);
      }
    }
    return dados;
  },
  saveProposta: function (value: Proposta) {
    saveLocalStorage(PROPOSTA, value);
  },
  getProposta: function () {
    let dados = {} as Proposta;
    if (window.localStorage.getItem(PROPOSTA)) {
      const stringDados = window.localStorage.getItem(PROPOSTA);
      if (stringDados) {
        dados = JSON.parse(stringDados);
      }
    }
    return dados;
  },

  saveAutenticacao: function (value: Autenticacao) {
    saveLocalStorage(AUTENTICACAO, value);
  },
  getAutenticacao: function () {
    let dados = {} as Autenticacao;
    if (window.localStorage.getItem(AUTENTICACAO)) {
      const stringDados = window.localStorage.getItem(AUTENTICACAO);
      if (stringDados) {
        dados = JSON.parse(stringDados);
      }
    }
    return dados;
  },
  saveTokenRecaptcha: function (value: string) {
    saveLocalStorage(TOKENRECAPTCHA, value);
  },
  getTokenRecaptcha: function () {
    let dados = {} as string;
    if (window.localStorage.getItem(TOKENRECAPTCHA)) {
      const stringDados = window.localStorage.getItem(TOKENRECAPTCHA);
      if (stringDados) {
        dados = JSON.parse(stringDados);
      }
    }
    return dados;
  },
  saveIP: function (value: string) {
    saveLocalStorage(IP, value);
  },
  getIP: function () {
    let dados = "";
    if (window.localStorage.getItem(IP)) {
      const stringDados = window.localStorage.getItem(IP);
      if (stringDados) {
        dados = JSON.parse(stringDados);
      }
    }
    return dados;
  },
  saveDevice: function (value: DeviceDetectorResult) {
    saveLocalStorage(DEVICE, value);
  },
  getDevice: function () {
    let dados = {} as DeviceDetectorResult;
    if (window.localStorage.getItem(DEVICE)) {
      const stringDados = window.localStorage.getItem(DEVICE);
      if (stringDados) {
        dados = JSON.parse(stringDados);
      }
    }
    return dados;
  },
  saveTask: function (value: Carrinho) {
    saveLocalStorage(CARRINHO, value);
  },
  getTask: function () {
    let dados = {} as Carrinho;
    if (window.localStorage.getItem(CARRINHO)) {
      const stringDados = window.localStorage.getItem(CARRINHO);
      if (stringDados) {
        dados = JSON.parse(stringDados);
      }
    }
    return dados;
  },
  saveCall: function () {
    saveLocalStorage(CALL, true);
  },
  getCall: function () {
    return window.localStorage.getItem(CALL);
  },
};
