import { resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "myModal",
  "data-bs-backdrop": "static",
  tabindex: "-1",
  "aria-labelledby": "myModal",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.valueComputed)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.state.component), _normalizeProps(_mergeProps({ key: 0 }, _ctx.state.props)), null, 16))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}