import { Persist } from "../../../utils/localStorage";

export type State = {
  dadosPessoais: DadosPessoais;
  emailForm: EmailForm;
  ip: string;
};
export const state: State = {
  dadosPessoais: Persist.getDadosdPessoais(),
  emailForm: {} as EmailForm,
  ip: Persist.getIP(),
};
