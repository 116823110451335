import { RootState } from "@/store";
import { GetterTree } from "vuex";

import { State } from "./state";

// Getters types
export type Getters = {
  dadosPessoais(state: State): DadosPessoais | null;
  ip(state: State): string;
};

export const getters: GetterTree<State, RootState> & Getters = {
  dadosPessoais: (state) => state.dadosPessoais,
  ip: (state) => state.ip,
};
