import { AxiosError, AxiosInstance } from "axios";

async function getProvaVida(httpClient: AxiosInstance): Promise<{
  data: any;
  errors: AxiosError | null;
}> {
  let errors = null;
  let data = null;
  await httpClient
    .post("api/v1/application/validation")
    .then((response) => {
      data = response.data;
    })
    .catch((e) => {
      errors = e;
    });
  return { data, errors };
}
async function postVerificarCapturaDocumento(
  httpClient: AxiosInstance
): Promise<{
  data: any;
  errors: AxiosError<DataError> | null;
}> {
  let errors = null;
  let data = null;
  await httpClient
    .post("api/v1/application/verify-document-capture")
    .then((response) => {
      data = response.data;
    })
    .catch((e) => {
      errors = e;
    });
  return { data, errors };
}

export default (httpClient: AxiosInstance) => ({
  chamarProvaVida: async () => {
    const { data, errors } = await getProvaVida(httpClient);

    return { data, errors };
  },
  verificarProvaVida: async () => {
    const { data, errors } = await postVerificarCapturaDocumento(httpClient);

    return { data, errors };
  },
});
