export enum GeralActionTypes {
  EXCLUIR_ORDEM_COMPRA = "EXCLUIR_ORDEM_COMPRA",
  ENVIAR_ORDEM_COMPRA = "ENVIAR_ORDEM_COMPRA",
  ENVIAR_OFERTA_SELECIONADA = "ENVIAR_OFERTA_SELECIONADA",
  ENVIAR_AUTORIZACOES = "ENVIAR_AUTORIZACOES",
  EFETUAR_LOGIN = "EFETUAR_LOGIN",
  GET_INFO_DEVICE = "GET_INFO_DEVICE",
  ENVIAR_TOKEN = "ENVIAR_TOKEN",
  LIBERAR_PROPOSTA = "LIBERAR_PROPOSTA",
  VALIDAR_LIGACAO = "VALIDAR_LIGACAO",
  BUSCAR_PROPOSTA = "BUSCAR_PROPOSTA",
}
