export enum GeralMutationTypes {
  EXCLUIR_ORDEM_COMPRA = "EXCLUIR_ORDEM_COMPRA",
  INSERIR_ORDEM_COMPRA = "INSERIR_ORDEM_COMPRA",
  INSERIR_PROPOSTA = "INSERIR_PROPOSTA",
  INSERIR_PROPOSTA_INFO = "INSERIR_PROPOSTA_INFO",
  SET_LOADING = "SET_LOADING",
  SET_AUTENTICACAO = "SET_AUTENTICACAO",
  SET_TOKENRECAPTCHA = "SET_TOKENRECAPTCHA",
  SET_DEVICE = "SET_DEVICE",
  SET_RECUPERAR_CARRINHO = "SET_RECUPERAR_CARRINHO",
}
