import { MutationTree } from "vuex";
import { State, Loading } from "./state";
import { GeralMutationTypes } from "./mutation-types";
import { Persist } from "../../../utils/localStorage";
import { DeviceDetectorResult } from "device-detector-js";

//Mutation Types
export type Mutations<S = State> = {
  [GeralMutationTypes.INSERIR_ORDEM_COMPRA](
    state: S,
    payload: OrdemCompra
  ): void;
  [GeralMutationTypes.SET_LOADING](state: State, loading: Loading): void;
  [GeralMutationTypes.SET_RECUPERAR_CARRINHO](
    state: State,
    payload: Carrinho
  ): void;
  [GeralMutationTypes.INSERIR_PROPOSTA](state: State, payload: Proposta): void;
  [GeralMutationTypes.SET_AUTENTICACAO](
    state: State,
    payload: Autenticacao
  ): void;
  [GeralMutationTypes.SET_TOKENRECAPTCHA](state: State, payload: string): void;
  [GeralMutationTypes.SET_DEVICE](
    state: State,
    payload: DeviceDetectorResult
  ): void;
};

//define mutations
export const mutations: MutationTree<State> & Mutations = {
  [GeralMutationTypes.SET_DEVICE](state: State, payload: DeviceDetectorResult) {
    state.device = payload;
    Persist.saveDevice(payload);
  },
  [GeralMutationTypes.INSERIR_ORDEM_COMPRA](
    state: State,
    payload: OrdemCompra
  ) {
    state.ordemCompra = payload;
    Persist.saveOrdemCompra(payload);
  },
  [GeralMutationTypes.SET_LOADING](state: State, loading: Loading) {
    state.loading = loading;
  },
  [GeralMutationTypes.INSERIR_PROPOSTA](state: State, payload: Proposta) {
    state.proposta = payload;
    Persist.saveProposta(payload);
  },
  [GeralMutationTypes.SET_AUTENTICACAO](state: State, payload: Autenticacao) {
    state.autenticacao = payload;
    Persist.saveAutenticacao(payload);
  },
  [GeralMutationTypes.SET_TOKENRECAPTCHA](state: State, payload: string) {
    state.tokenRecaptcha = payload;
    Persist.saveTokenRecaptcha(payload);
  },
  [GeralMutationTypes.SET_RECUPERAR_CARRINHO](state: State, payload: Carrinho) {
    state.carrinho = payload;
    Persist.saveTask(payload);
  },
};
