import useModal from "@/hooks/useModal";
import router from "@/router";
import { useStore } from "@/store";
import { GeralMutationTypes } from "@/store/modules/geral/mutation-types";
import { Loading } from "@/store/modules/geral/state";
import * as Modais from "@/types/Modais";
import { DateTime } from "luxon";
import { Persist } from "../utils/localStorage";
import { GeralActionTypes } from "@/store/modules/geral/action-types";

export default function reusableFunctions() {
  const store = useStore();
  const modal = useModal();

  function queryString(param: any) {
    return Object.keys(param)
      .map((key) => `${key}=${encodeURIComponent(param[key])}`)
      .join("&");
  }

  function converterData(data: string) {
    if (DateTime.fromFormat(data, "dd/MM/yyyy").isValid) {
      const novaData = DateTime.fromFormat(data, "dd/MM/yyyy").toFormat(
        "yyyy-MM-dd"
      );
      return novaData;
    } else if (DateTime.fromFormat(data, "yyyy-MM-dd").isValid) {
      return data;
    }
  }
  function converterDateTime(data: string) {
    const novaData = DateTime.fromISO(data).toFormat("dd/MM/yyyy - HH:mm");
    return novaData;
  }

  function loadingTrue() {
    store.commit(GeralMutationTypes.SET_LOADING, {
      exibir: true,
    } as Loading);
  }
  function loadingTrueMsg(texto: string) {
    store.commit(GeralMutationTypes.SET_LOADING, {
      exibir: true,
      texto: texto,
    } as Loading);
  }
  function loadingFalse() {
    store.commit(GeralMutationTypes.SET_LOADING, {
      exibir: false,
    } as Loading);
  }
  function verificarConexaoInternet() {
    if (navigator.onLine) {
      return true;
    } else {
      modal.open({
        component: "ModalInfoGenerico",
        props: {
          msg: "Necesitas tener conexión a Internet para pedir tu Préstamo Sin Intereses",
          titulo: "Sin conexión a Internet",
          icone: "icon-i-red.svg",
        },
      });
      return false;
    }
  }
  function falhaConexao() {
    modal.open({
      component: "ModalInfoGenerico",
      props: {
        msg: "¡Ups! Estamos presentando problemas técnicos, ya estamos trabajando para solucionarlo",
        titulo: "Falló la conexión",
        icone: "icon-i-red.svg",
        tipoBtn: "sair",
      },
    });
  }

  function modalSair() {
    modal.open({
      component: "ModalSair",
      props: {},
    });
  }

  async function getProposta() {
    if (!store.getters.proposta) {
      const { data, errors } = await store.dispatch(
        GeralActionTypes.BUSCAR_PROPOSTA
      );
      if (!errors && data) {
        Persist.saveProposta(data);
        return data;
      }
    }
    return store.getters.proposta;
  }

  function getUrlReturn(proposta: Proposta) {
    return (
      proposta.data.urlReturn +
      "?numOrderEcom=" +
      proposta.data.numOrderEcom +
      "&numCredit=" +
      proposta.data.numCredit
    );
  }
  function getUrlFail(proposta: Proposta) {
    const description = proposta.data.description
      ? proposta.data.description
      : "";
    return (
      proposta.data.urlFail +
      "?numOrderEcom=" +
      proposta.data.numOrderEcom +
      "&numCredit=" +
      proposta.data.numCredit +
      "&status=REJECTED" +
      "&numError=" +
      proposta.data.numError +
      "&description=" +
      encodeURI(description)
    );
  }

  function getUrlSuccess(proposta: Proposta) {
    const signature = proposta.data.signature ? proposta.data.signature : "";
    return (
      proposta.data.urlSuccess +
      "?numOrderEcom=" +
      proposta.data.numOrderEcom +
      "&numCredit=" +
      proposta.data.numCredit +
      "&authorizationCode=" +
      proposta.data.authorizationCode +
      "&amount=" +
      proposta.data.amount +
      "&montoPagar=" +
      proposta.data.totalAmount +
      "&qtyCuotas=" +
      proposta.data.installmentsBiweekly +
      "&montoCuota=" +
      proposta.data.valueInstallment +
      "&periodo=QUINCENA" +
      "&folioCredit=" +
      proposta.data.folioCredit +
      "&comisionApertura=" +
      proposta.data.commission +
      "&signature=" +
      encodeURI(signature)
    );
  }
  async function rejection() {
    const proposta = await getProposta();
    if (proposta && proposta.data.origin === "Onboard") {
      window.location.href = getUrlFail(proposta);
    } else {
      await reusableFunctions().logOut();
    }
  }

  async function logOut() {
    const proposta = await getProposta();
    localStorage.clear();
    sessionStorage.clear();
    if (window.location.href.includes("onboard?")) {
      const url = new URL(window.location.href.replace("#", ""));
      const returnUrl = decodeURI(url.searchParams.get("returnUrl") as string);
      window.location.href = returnUrl;
    } else if (proposta && proposta.data.origin === "Onboard") {
      window.location.href = getUrlReturn(proposta);
    } else {
      router.push({ name: "Home" });
      window.location.reload();
    }
  }

  async function terminar() {
    const proposta = await getProposta();
    if (window.location.href.includes("onboard?")) {
      const url = new URL(window.location.href.replace("#", ""));
      const sucessUrl = decodeURI(url.searchParams.get("sucessUrl") as string);
      window.location.href = sucessUrl;
    } else if (proposta && proposta.data.origin === "Onboard") {
      window.location.href = getUrlSuccess(proposta);
    } else {
      localStorage.clear();
      sessionStorage.clear();
      router.push({ name: "Home" });
    }
  }

  function openModalGeneric(value: Modais.MyModalGeneric) {
    modal.open({
      component: "ModalInfoGenerico",
      props: {
        msg: value.msg,
        titulo: value.titulo,
        icone: value.icone,
      },
    });
  }
  function openModalAvisoPrivacidade() {
    modal.open({
      component: "ModalAvisoPrivacidade",
    });
  }
  function emManutencao() {
    modal.open({
      component: "ModalInfoGenerico",
      props: {
        msg: "¡Estamos en mantenimiento! Seguimos trabajando para darte una mejor experiencia",
        titulo: "Sitio web bajo mantenimiento",
        icone: "icon-i-red.svg",
      },
    });
  }
  function removeAccents(text: string) {
    const accentsMap = new Map([
      ["A", "Á|À|Ã|Â|Ä"],
      ["a", "á|à|ã|â|ä"],
      ["E", "É|È|Ê|Ë"],
      ["e", "é|è|ê|ë"],
      ["I", "Í|Ì|Î|Ï"],
      ["i", "í|ì|î|ï"],
      ["O", "Ó|Ò|Ô|Õ|Ö"],
      ["o", "ó|ò|ô|õ|ö"],
      ["U", "Ú|Ù|Û|Ü"],
      ["u", "ú|ù|û|ü"],
      ["C", "Ç"],
      ["c", "ç"],
    ]);

    const reducer = (acc: string, [key]: any) => {
      const abc = accentsMap.get(key);
      if (abc) return acc.replace(new RegExp(abc, "g"), key);
      return "";
    };
    if (text) {
      return [...accentsMap].reduce(reducer, text);
    }
  }
  function tratarRejection(stringDados: string) {
    const rejection = JSON.parse(stringDados) as Rejection;
    modal.open({
      component: "ModalRejection",
      props: {
        rejection: rejection,
      },
    });
  }
  return {
    loadingTrue,
    loadingFalse,
    loadingTrueMsg,
    verificarConexaoInternet,
    falhaConexao,
    modalSair,
    logOut,
    terminar,
    openModalGeneric,
    openModalAvisoPrivacidade,
    converterData,
    queryString,
    emManutencao,
    converterDateTime,
    removeAccents,
    tratarRejection,
    getUrlFail,
    getUrlSuccess,
    getUrlReturn,
    getProposta,
    rejection,
  };
}
