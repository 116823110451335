//hook para usar modal
import { Callback } from "yup/lib/types";
import bus from "../utils/bus";

const EVENT_NAME = "modal";

export default function useModal() {
  function open(payload = {}) {
    bus.emit(EVENT_NAME, { status: true, ...payload });
    //console.log(payload)
  }
  function close(payload = {}) {
    bus.emit(EVENT_NAME, { status: false, ...payload });
  }
  function listen(fn: (payload: Callback) => void) {
    bus.on(EVENT_NAME, fn);
  }
  function off(fn: (payload: Callback) => void) {
    bus.off(EVENT_NAME, fn);
  }
  return { open, close, listen, off };
}
