import { ActionContext, ActionTree } from "vuex";
import { Mutations } from "./mutations";
import { Mutations as MutationsGeral } from "../geral/mutations";
import { State } from "./state";
import { DadosPessoaisActionTypes } from "./action-types";
import { DadosPessoaisMutationTypes } from "./mutation-types";
import { RootState } from "@/store";
import services from "../../../services";
import { AxiosError } from "axios";
import { GeralMutationTypes } from "../geral/mutation-types";

//actions
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
  commit<K extends keyof MutationsGeral>(
    key: K,
    payload: Parameters<MutationsGeral[K]>[1]
  ): ReturnType<MutationsGeral[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

// actions interface
export interface Actions {
  [DadosPessoaisActionTypes.BUSCAR_DADOS_PESSOAIS](
    { commit }: AugmentedActionContext,
    payload: string
  ): Promise<{
    data: DadosPessoais | null;
    errors: AxiosError<unknown, any> | null;
  }>;
  [DadosPessoaisActionTypes.ENVIAR_DADOS_PESSOAIS](
    { commit }: AugmentedActionContext,
    payload: DadosPessoais
  ): Promise<{ data: any; errors: AxiosError<unknown, any> | null }>;
  [DadosPessoaisActionTypes.EXCLUIR_DADOS_PESSOAIS](
    { commit }: AugmentedActionContext,
    payload: number
  ): void;
  [DadosPessoaisActionTypes.ENVIAR_FORM_EMAIL](
    { commit }: AugmentedActionContext,

    payload: EmailForm
  ): Promise<{
    data: Proposta | null;
    errors: AxiosError<unknown, any> | null;
  }>;
  [DadosPessoaisActionTypes.BUSCAR_IP](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<{
    data: any;
    errors: AxiosError<unknown, any> | null;
  }>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [DadosPessoaisActionTypes.BUSCAR_IP]({ commit }) {
    const { data, errors } = await services.dadosPessoais.obterIP();
    if (!errors && data) {
      commit(DadosPessoaisMutationTypes.GUARDAR_IP, data);
    } else {
      const { data, errors } = await services.dadosPessoais.obterIP2();
      if (!errors && data) commit(DadosPessoaisMutationTypes.GUARDAR_IP, data);
    }
    return { data, errors };
  },
  async [DadosPessoaisActionTypes.ENVIAR_DADOS_PESSOAIS](
    { commit },
    payload: DadosPessoais
  ) {
    const { data, errors } = await services.dadosPessoais.enviarDadosPessoais(
      payload
    );
    if (!errors) {
      commit(DadosPessoaisMutationTypes.SALVAR_DADOS_PESSOAIS, payload);
    }
    return { data, errors };
  },
  async [DadosPessoaisActionTypes.BUSCAR_DADOS_PESSOAIS]({ commit }) {
    const { data, errors } = await services.dadosPessoais.buscarDadosPessoais();
    if (!errors && data) {
      commit(DadosPessoaisMutationTypes.SALVAR_DADOS_PESSOAIS, data);
    }
    return { data, errors };
  },
  [DadosPessoaisActionTypes.EXCLUIR_DADOS_PESSOAIS](
    { commit },
    payload: number
  ) {
    commit(DadosPessoaisMutationTypes.EXCLUIR_DADOS_PESSOAIS, payload);
  },
  async [DadosPessoaisActionTypes.ENVIAR_FORM_EMAIL](
    { commit },
    payload: EmailForm
  ) {
    const { data, errors } = await services.dadosPessoais.enviarEmail(payload);
    if (!errors && data) {
      commit(DadosPessoaisMutationTypes.ENVIAR_FORM_EMAIL, payload);
      commit(GeralMutationTypes.INSERIR_PROPOSTA, data);
    }
    return { data, errors };
  },
};
