import { Persist } from "@/utils/localStorage";
import { DeviceDetectorResult } from "device-detector-js";

export type Loading = {
  exibir: boolean;
  texto?: string;
};
export type State = {
  proposta: Proposta | null;
  ordemCompra: OrdemCompra | null;
  loading: Loading;
  tipoConta: string;
  autenticacao: Autenticacao;
  tokenRecaptcha: string;
  device: DeviceDetectorResult;
  carrinho: Carrinho;
};
export const state: State = {
  proposta: Persist.getProposta(),
  ordemCompra: Persist.getOrdemCompra(),
  tipoConta: "",
  loading: { exibir: true } as Loading,
  autenticacao: Persist.getAutenticacao(),
  tokenRecaptcha: Persist.getTokenRecaptcha(),
  device: {} as DeviceDetectorResult,
  carrinho: {} as Carrinho,
};
