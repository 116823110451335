<template>
  <div class="d-grid justify-content-center loading">
    <div class="spinner-border text-light" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <span class="text-loading mt-4">{{ texto }}</span>
  </div>
</template>

<script>
export default {
  props: {
    texto: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
.d-flex {
  flex-wrap: wrap;
}
.d-grid {
  justify-items: center;
}
.text-loading {
  font-family: "Telefonica";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: rgba(248, 249, 250, 1) !important;
  z-index: 999;
}
.spinner-border {
  width: 4rem !important;
  height: 4rem !important;
}
.loading {
  position: fixed;
  z-index: 998;
  height: 4em;
  width: 85%;
  overflow: show;
  margin: auto;
  top: -20%;
  left: 0;
  bottom: 0;
  right: 0;
}
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
</style>
