import { AxiosError, AxiosInstance } from "axios";

async function post(
  httpClient: AxiosInstance,
  dadosPessoais: DadosPessoais
): Promise<{
  data: DadosPessoais | null;
  errors: AxiosError | null;
}> {
  let errors = null;
  let data = null;
  await httpClient
    .post("api/v1/application/save-personal-data", dadosPessoais)
    .then((response) => {
      data = response.data;
    })
    .catch((e) => {
      errors = e;
    });
  return { data, errors };
}
async function postEmail(
  httpClient: AxiosInstance,
  emailForm: EmailForm
): Promise<{
  data: Proposta | null;
  errors: AxiosError | null;
}> {
  let errors = null;
  let data = null;
  await httpClient
    .post("api/v1/application/email", emailForm)
    .then((response) => {
      data = response.data;
    })
    .catch((e) => {
      errors = e;
    });
  return { data, errors };
}

async function getCep(
  httpClient: AxiosInstance,
  cep: string
): Promise<{
  data: Colonia[];
  errors: AxiosError<DataError> | null;
}> {
  let errors = null;
  let data: Colonia[] = [];
  await httpClient
    .get("api/v1/application/neighborhoods/" + `${cep}`)
    .then((response) => {
      data = response.data.records;
    })
    .catch((e) => {
      errors = e;
    });
  return { data, errors };
}
async function getEstados(httpClient: AxiosInstance): Promise<{
  data: Estado[];
  errors: AxiosError | null;
}> {
  let errors = null;
  let data: Estado[] = [];
  await httpClient
    .get("/api/v1/application/states")
    .then((response) => {
      data = response.data.records;
    })
    .catch((e) => {
      errors = e;
    });
  return { data, errors };
}
async function getDadosPessoais(httpClient: AxiosInstance): Promise<{
  data: DadosPessoais | null;
  errors: AxiosError | null;
}> {
  let errors = null;
  let data = null;
  await httpClient
    .get("api/v1/application/get-personal-data/")
    .then((response) => {
      data = response.data;
    })
    .catch((e) => {
      errors = e;
    });
  return { data, errors };
}
async function pegarIP(httpClient: AxiosInstance): Promise<{
  data: string | null;
  errors: AxiosError | null;
}> {
  let data = null;
  let errors = null;
  await httpClient
    .get("https://api.ipify.org?format=json")
    .then((response) => {
      data = response.data.ip;
    })
    .catch((e) => {
      errors = e;
    });
  return { data, errors };
}
async function pegarIP2(httpClient: AxiosInstance): Promise<{
  data: string | null;
  errors: AxiosError | null;
}> {
  let data = null;
  let errors = null;
  await httpClient
    .get("https://api.bigdatacloud.net/data/client-ip")
    .then((response) => {
      data = response.data.ipString;
    })
    .catch((e) => {
      errors = e;
    });
  return { data, errors };
}

export default (httpClient: AxiosInstance) => ({
  enviarDadosPessoais: async (dadosPessoais: DadosPessoais) => {
    const { data, errors } = await post(httpClient, dadosPessoais);
    return { data, errors };
  },
  buscarDadosPessoais: async () => {
    const { data, errors } = await getDadosPessoais(httpClient);
    return { data, errors };
  },
  enviarEmail: async (emailForm: EmailForm) => {
    const { data, errors } = await postEmail(httpClient, emailForm);
    return { data, errors };
  },
  buscarCep: async (cep: string) => {
    const { data, errors } = await getCep(httpClient, cep);
    return { data, errors };
  },
  buscarEstados: async () => {
    const { data, errors } = await getEstados(httpClient);
    return { data, errors };
  },
  obterIP: async () => {
    const { data, errors } = await pegarIP(httpClient);
    return { data, errors };
  },
  obterIP2: async () => {
    const { data, errors } = await pegarIP2(httpClient);
    return { data, errors };
  },
});
