import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MyIdle = _resolveComponent("MyIdle")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_ModalFactory = _resolveComponent("ModalFactory")!
  const _component_RecaptchaComponent = _resolveComponent("RecaptchaComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MyIdle),
    _createVNode(_component_router_view),
    (_ctx.storeLoading.exibir)
      ? (_openBlock(), _createBlock(_component_Loading, {
          key: 0,
          texto: _ctx.storeLoading.texto
        }, null, 8, ["texto"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ModalFactory),
    _createVNode(_component_RecaptchaComponent)
  ], 64))
}