import { RootState } from "@/store";
import { DeviceDetectorResult } from "device-detector-js";
import { GetterTree } from "vuex";

import { Loading, State } from "./state";

// Getters types
export type Getters = {
  ordemCompra(state: State): OrdemCompra | null;
  loading(state: State): Loading;
  tipoConta(state: State): string;
  proposta(state: State): Proposta | null;
  autenticacao(state: State): Autenticacao;
  tokenRecaptcha(state: State): string;
  device(state: State): DeviceDetectorResult;
  carrinho(state: State): Carrinho;
};

export const getters: GetterTree<State, RootState> & Getters = {
  ordemCompra: (state) => state.ordemCompra,
  loading: (state) => state.loading,
  tipoConta: (state) => state.tipoConta,
  proposta: (state) => state.proposta,
  autenticacao: (state) => state.autenticacao,
  tokenRecaptcha: (state) => state.tokenRecaptcha,
  device: (state) => state.device,
  carrinho: (state) => state.carrinho,
};
