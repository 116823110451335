import axios from "axios";
import OrdemCompraService from "./ordemCompraService";
import OfertaService from "./ofertaService";
import DadosPessoais from "./dadosPessoaisService";
import ProvaVida from "./provaVida";
import reusableFunctions from "@/utils/functions";
import bbva from "./bbva";
import { store } from "@/store";
import { useToast } from "vue-toastification";
import { datadogRum } from "@datadog/browser-rum";

let online = false;

const API_URL = process.env.VUE_APP_API_URL;
const auth = "Basic Y3JlZGlzZmVyYTpjcmVkaXNmZXJh";
const contentType = "application/x-www-form-urlencoded";
const urlLogin = "api/v1/oauth/token";
const listaUrlIgnoreSetHeaders = [
  "https://api.ipify.org?format=json",
  "https://api.bigdatacloud.net/data/client-ip",
];
const toast = useToast();

const httpClient = axios.create({
  baseURL: API_URL,
});

httpClient.interceptors.request.use((config) => {
  online = reusableFunctions().verificarConexaoInternet();
  if (online) {
    if (config.url && !listaUrlIgnoreSetHeaders.includes(config.url)) {
      if (config.url === urlLogin && config.headers) {
        config.headers["Authorization"] = `${auth}`;
        config.headers["Content-Type"] = `${contentType}`;
      } else {
        const token = store.getters.autenticacao.access_token;
        if (token) {
          if (config.headers) {
            config.headers["Authorization"] = `Bearer ${token}`;
          }
        }
      }
    }
  } else {
    return Promise.reject(new Error("No conection Internet"));
  }
  return config;
});

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log(error);
    if (online) {
      if (!error.response) {
        datadogRum.addError(error);
        reusableFunctions().falhaConexao();
      } else {
        datadogRum.addError(error.response);
        if (error.response.status === 417) {
          reusableFunctions().tratarRejection(error.response.data.message);
        } else if (listaUrlIgnoreSetHeaders.includes(error.config.url)) {
          toast.clear();
          toast.error("Error al obtener IP");
        } else if (
          error.message === "Network Error" ||
          error.code === "ERR_NETWORK" ||
          error.status === 504 ||
          error.response.status === 503 ||
          error.response.status === 500
        ) {
          reusableFunctions().falhaConexao();
        } else if (error.response.status === 403) {
          await reusableFunctions().logOut();
        } else if (error.response.status === 401) {
          toast.clear();
          if (error.response.data.message === "Captcha no es válido.") {
            toast.error("ReCAPTCHA inválido");
          } else if (error.response.status === 410) {
            toast.error("¡Link caducado!");
          } else {
            toast.error("¡La autenticación expiró!");
            new Promise((resolve) => setTimeout(resolve, 5000)).then(
              async () => {
                await reusableFunctions().logOut();
              }
            );
          }
        } else if (
          error.response.status === 424 ||
          error.response.status === 400
        ) {
          toast.clear();
          toast.error(error.response.data.message);
        }
      }
    }
    return Promise.reject(error);
  }
);

export default {
  ordemCompra: OrdemCompraService(httpClient),
  oferta: OfertaService(httpClient),
  dadosPessoais: DadosPessoais(httpClient),
  bbva: bbva(httpClient),
  provaVida: ProvaVida(httpClient),
};
