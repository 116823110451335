import { AxiosError, AxiosInstance } from "axios";

export async function getAll(httpClient: AxiosInstance): Promise<{
  data: Oferta[];
  errors: AxiosError | null;
}> {
  let errors = null;
  let data: Oferta[] = [];
  await httpClient
    .get("api/v1/application/offers")
    .then((response) => {
      data = response.data.offers;
    })
    .catch((e) => {
      errors = e;
    });
  return { data, errors };
}

export async function getResumo(httpClient: AxiosInstance): Promise<{
  data: Oferta | null;
  errors: AxiosError | null;
}> {
  let errors = null;
  let data = null;
  await httpClient
    .get("api/v1/application/offer-summary")
    .then((response) => {
      data = response.data;
    })
    .catch((e) => {
      errors = e;
    });
  return { data, errors };
}
export async function getResumoFinal(httpClient: AxiosInstance): Promise<{
  data: Oferta | null;
  errors: AxiosError | null;
}> {
  let errors = null;
  let data = null;
  await httpClient
    .get("api/v1/application/offer-summary-final")
    .then((response) => {
      data = response.data;
    })
    .catch((e) => {
      errors = e;
    });
  return { data, errors };
}

export async function post(
  httpClient: AxiosInstance,
  ofertaSelecionada: OfertaSelecionada
): Promise<{
  data: Proposta | null;
  errors: AxiosError | null;
}> {
  let errors = null;
  let data = null;
  await httpClient
    .post("api/v1/application/installments", ofertaSelecionada)
    .then((response) => {
      data = response.data;
    })
    .catch((e) => {
      errors = e;
    });
  return { data, errors };
}
export async function postSolicitarEmprestimo(
  httpClient: AxiosInstance,
  resumoRequest: ResumoRequest
): Promise<{
  data: Proposta | null;
  errors: AxiosError | null;
}> {
  let errors = null;
  let data = null;
  await httpClient
    .post("api/v1/application/apply-loan", resumoRequest)
    .then((response) => {
      data = response.data;
    })
    .catch((e) => {
      errors = e;
    });
  return { data, errors };
}

export default (httpClient: AxiosInstance) => ({
  enviar: async (ofertaSelecionada: OfertaSelecionada) => {
    const { data, errors } = await post(httpClient, ofertaSelecionada);
    return { data, errors };
  },
  buscarOfertas: async () => {
    const { data, errors } = await getAll(httpClient);
    return { data, errors };
  },
  buscarResumo: async () => {
    const { data, errors } = await getResumo(httpClient);
    return { data, errors };
  },
  buscarResumoFinal: async () => {
    const { data, errors } = await getResumoFinal(httpClient);
    return { data, errors };
  },
  solicitarEmprestimo: async (resumoRequest: ResumoRequest) => {
    const { data, errors } = await postSolicitarEmprestimo(
      httpClient,
      resumoRequest
    );
    return { data, errors };
  },
});
