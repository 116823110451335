<template>
  <MyIdle />
  <router-view />
  <Loading v-if="storeLoading.exibir" :texto="storeLoading.texto" />
  <ModalFactory />
  <RecaptchaComponent />
</template>
<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";
import ModalFactory from "./components/modal/ModalFactory.vue";
import Loading from "./components/MyLoading.vue";
import { useStore } from "@/store";
import MyIdle from "./components/MyIdle.vue";
import RecaptchaComponent from "@/components/RecaptchaComponent.vue";
import { GeralActionTypes } from "./store/modules/geral/action-types";
import { datadogRum } from "@datadog/browser-rum";

export default defineComponent({
  components: { ModalFactory, Loading, MyIdle, RecaptchaComponent },
  setup() {
    onMounted(() => {
      if (Object.keys(store.getters.device).length === 0)
        store.dispatch(GeralActionTypes.GET_INFO_DEVICE);
      if (process.env.VUE_APP_DD_ENV === "prod") {
        datadogRum.init({
          applicationId: process.env.VUE_APP_DD_APP_ID,
          clientToken: process.env.VUE_APP_DD_TOKEN,
          site: process.env.VUE_APP_DD_SITE,
          service: process.env.VUE_APP_DD_SERVICE,
          env: process.env.VUE_APP_DD_ENV,
          sessionSampleRate: 100,
          sessionReplaySampleRate: 40,
          trackInteractions: true,
          trackUserInteractions: process.env.VUE_APP_DD_TRACK_USER_INTERACTIONS,
          trackResources: process.env.VUE_APP_DD_TRACK_RESOURCES,
          trackLongTasks: process.env.VUE_APP_DD_TRACK_LONG_TASKS,
          trackFrustrations: true,
          defaultPrivacyLevel: process.env.VUE_APP_DD_PRIVACY_LEVEL,
          allowedTracingUrls: [process.env.VUE_APP_DD_ALLOWED_TRACING_ORIGIN],
        });

        datadogRum.startSessionReplayRecording();
      }
    });
    const store = useStore();
    const storeLoading = computed(() => store.getters.loading);

    return { storeLoading };
  },
});
</script>

<style></style>
