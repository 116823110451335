import { MutationTree } from "vuex";
import { State } from "./state";
import { DadosPessoaisMutationTypes } from "./mutation-types";
import { Persist } from "../../../utils/localStorage";

//Mutation Types
export type Mutations<S = State> = {
  [DadosPessoaisMutationTypes.EXCLUIR_DADOS_PESSOAIS](
    state: S,
    payload: number
  ): void;
  [DadosPessoaisMutationTypes.SALVAR_DADOS_PESSOAIS](
    state: State,
    payload: DadosPessoais
  ): void;
  [DadosPessoaisMutationTypes.ENVIAR_FORM_EMAIL](
    state: State,
    payload: EmailForm
  ): void;
  [DadosPessoaisMutationTypes.GUARDAR_IP](state: State, payload: string): void;
};

//define mutations
export const mutations: MutationTree<State> & Mutations = {
  [DadosPessoaisMutationTypes.GUARDAR_IP](state: State, payload: string) {
    state.ip = payload;
  },
  [DadosPessoaisMutationTypes.EXCLUIR_DADOS_PESSOAIS](state: State) {
    state.dadosPessoais = {} as DadosPessoais;
    Persist.clearOrdemCompra();
  },
  [DadosPessoaisMutationTypes.SALVAR_DADOS_PESSOAIS](
    state: State,
    payload: DadosPessoais
  ) {
    state.dadosPessoais = payload;
    Persist.saveDadosdPessoais(payload);
  },
  [DadosPessoaisMutationTypes.ENVIAR_FORM_EMAIL](
    state: State,
    payload: EmailForm
  ) {
    state.emailForm = payload;
  },
};
