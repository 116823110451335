<template>
  <div v-if="state.exibir">
    <teleport to="#myRecaptcha">
      <div class="center">
        <div id="inline-badge"></div>
      </div>
    </teleport>
  </div>
</template>

<script lang="ts">
import { useStore } from "@/store";
import { GeralMutationTypes } from "@/store/modules/geral/mutation-types";
import { load, ReCaptchaInstance } from "recaptcha-v3";
import { defineComponent, reactive, watch } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      exibir: false,
    });
    let recaptcha: ReCaptchaInstance;

    async function teste() {
      if (route.name === "OrdemCompra") {
        recaptcha = await load(process.env?.VUE_APP_G_RECAPTCHA_SITE_KEY, {
          autoHideBadge: false,
        });
        const classname = document.getElementsByClassName("grecaptcha-badge");
        if (!classname[0]) window.location.reload();
        const token = await recaptcha.execute("login");
        store.commit(GeralMutationTypes.SET_TOKENRECAPTCHA, token);
      }
    }
    watch(
      () => route.name as string,
      async (rotaName: string) => {
        if (["OrdemCompra"].includes(rotaName)) {
          state.exibir = true;
          await teste();
          recaptcha.showBadge();
        } else {
          state.exibir = false;
          if (recaptcha) recaptcha.hideBadge();
        }
      }
    );
    return { state };
  },
});
</script>
